
// type
// 0 个人
// 1 企业
export default function appMenuList(type) {
    let settleChildren = [{
        title: '结算账单',
        path: '/settle/bill'
    }];
    if(type === 1) {
        settleChildren.push({
            title: '提现申请',
            path: '/settle/withdrawal'
        });
    }
    let menuList = [{
        title: '我要推广',
        icon: 'el-icon-s-promotion',
        children: [{
            title: '商品推广',
            path: '/promotion/goods'
        }, {
            title: '主题推广',
            path: '/promotion/activity'
        }, {
            title: '奖励活动推广',
            path: '/promotion/reward'
        },
        {
            title: '推广位管理',
            path: '/promotion/promoteManage'
        }]
    }, {
        title: '数据看板',
        icon: 'el-icon-s-data',
        children: [{
            title: '整体报表',
            path: '/report/all'
        }, {
            title: '订单明细',
            path: '/report/order'
        }, {
            title: '奖励活动',
            path: '/report/reward'
        }]
    }, {
        title: '结算中心',
        icon: 'el-icon-s-grid',
        children: settleChildren
    }, {
        title: '我的工具',
        icon: 'el-icon-s-tools',
        children: [{
            title: '超级转链',
            path: '/tools/transformLink'
        }, {
            title: '预付订单查询',
            path: '/tools/advanceOrderSearch'
        },
        {
            title: '红包推广查询',
            path: '/tools/redPacketPromotion'
        },
        {
                title: '赚密令',
                path: '/tools/seo/list'
        }]
    }];

    if(type === 1) {
        menuList.push({
            title: '开发者中心',
            icon: 'el-icon-document',
            path: '/development'
        });
    }

    return menuList;
}
