
export default [{
    path: '/settle/bill',
    name: 'settle',
    component: () => import(/* webpackChunkName: "settle" */ '../../views/settle/index'),
    meta: { title: '结算账单' }
}, {
    path: '/settle/withdrawal',
    name: 'withdrawal',
    component: () => import(/* webpackChunkName: "withdrawal" */ '../../views/settle/withdrawal'),
    meta: { title: '提现申请' }
}];
