<template>
    <div class="home">
        <homehead :is-home-page="isHomePage" />
        <div class="home-main">
            <div class="home-bgpicture">
                <img :src="mainbgpicture" alt="首页背景图">
            </div>
            <div class="home-loginArea">
                <div class="home-login" :class="{ 'register': !zhuankeId && !companyQualInfo,
                                                  'is-show-notice':!isHasNotice}
                "
                >
                    <div class="home-no-login" v-if="!isLogin">
                        <p class="title">Hi，欢迎来到考拉赚客！</p>
                        <el-button type="info" round class="btn-login" @click="register">立即登录</el-button>
                        <el-button round class="btn-register" @click="register">
                            免费注册
                        </el-button>
                        <p class="tip">温馨提示：仅支持考拉账号登录</p>
                    </div>

                    <div v-if="isLogin">
                        <div v-if="zhuankeId || (!zhuankeId && companyQualInfo)">
                            <div class="home-userinfo">
                                <div class="home-userinfo-headimg">
                                    <img :src="kaolaHeadImageUrl">
                                </div>
                                <div style="width:100%">
                                    <p> Hi，{{ kaolaNickName }}  </p>
                                </div>

                                <el-button
                                    type="info"
                                    round class="userinfo-btn-login"
                                    @click="loginin"
                                >
                                    进入赚客
                                </el-button>
                            </div>
                            <div class="home-quit-login" @click="quitlogin">
                                退出登录
                            </div>
                        </div>

                        <div v-if="!zhuankeId && !companyQualInfo">
                            <div class="home-userinfo">
                                <div class="home-userinfo-headimg">
                                    <img :src="kaolaHeadImageUrl">
                                </div>
                                <div style="width:100%">
                                    <p> Hi，{{ kaolaNickName }}  </p>
                                </div>

                                <el-button
                                    type="info"
                                    round class="userinfo-btn-login"
                                    @click="loginin"
                                >
                                    开通企业赚客
                                </el-button>
                                <el-button
                                    type="info"
                                    round class="userinfo-btn-login-person"
                                    @click="registerPerson"
                                >
                                    开通个人赚客
                                </el-button>
                                <a href="/help?key=zhuanke_help_account" class="jumplink">个人赚客与企业赚客的差别</a>
                            </div>
                            <div class="home-quit-login" @click="quitlogin">
                                退出登录
                            </div>
                        </div>
                    </div>
                </div>
                <div class="home-Notice" v-if="isHasNotice">
                    <div class="home-Notice-top">
                        <div style="display:flex;align-items:baseline">
                            <img :src="notice_icon" alt="" style="width:15px;height:12px;margin-right:5px">
                            <span class="notice-title">公告</span>
                        </div>
                        <div style="display:flex;align-items:center" v-if="noticeAllList.length > 2" @click="gotoNoticePage">
                            <span class="notice-font"> 查看全部</span>
                            <img :src=" notice_right" alt="" style="width:5px;height:8px;margin:0 5px;">
                        </div>
                    </div>
                    <div class="home-Notice-bottom">
                        <div v-for="(v,index) in noticeList" :key="index" class="notice-font notice-list">
                            <span class="notice-content" @click="gotoDetail(v)">{{ v.title }}</span>
                            <span>{{ v.publishTime | formatTime('yyyy-MM-dd') }}</span>
                        </div>
                    </div>
                </div>
            </div>


            <el-dialog
                title="请仔细阅读并同意以下协议"
                :visible.sync="isagreement"
                width="45%"
                center
            >
                <div class="agreement-Dialog">
                    <div v-html="zhuankeHelpAgreement" />
                </div>

                <span slot="footer" class="dialog-footer">
                    <el-button style="width:30%" type="primary" round @click="gotocompanyQualAduit()">同意并继续</el-button>
                </span>
            </el-dialog>

            <el-dialog
                title="温馨提示"
                :visible.sync="regesiterDialogVisible"
                width="40%"
                :show-close="false"
            >
                <p>开通个人赚客后，暂不支持升级为企业赚客确认要开通个人赚客吗？</p>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="regesiterDialogVisible = false">我再想想</el-button>
                    <el-button type="primary" @click="registerV2">开通个人赚客</el-button>
                </span>
            </el-dialog>

            <el-dialog
                title="温馨提示"
                :visible.sync="bindDialogVisible"
                width="40%"
                :show-close="false"
            >
                <p>开通个人赚客需要绑定手机号，请前往考拉主站进行绑定</p>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="bindDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="bindPhone">去绑定</el-button>
                </span>
            </el-dialog>

            <el-dialog
                title="同意协议"
                :visible.sync="agreeDialogVisible"
                width="45%"
                :show-close="false"
            >
                <p><iframe src="https://zk.kaola.com/static/agreement.html" style="width: 100%; height:400px" frameborder="0" /></p>
                <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="agreeDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="agreeRules">同意</el-button>
                </span>
            </el-dialog>
        </div>
        <div class="home-adver">
            <img :src="adverpicture" alt="首页推广词背景图">
        </div>
        <homefoot />
        <ResignDialog v-if="zhuankeType === 0" />
    </div>
</template>

<script>
import { mapState } from 'vuex';
import homeHead from '../components/homeLayout/homeHead';
import homeFoot from '../components/homeLayout/homeFoot';
import logout from '../utils/logout';
import store from '../store';
import ResignDialog from '../components/homeLayout/resign-dialog';

const zkUrl = 'https://pub.kaola.com/';

export default {
    data(){
        return{
            isagreement: false,
            companyStatus: 0,
            mainbgpicture: 'https://img.alicdn.com/imgextra/i3/O1CN01kdVwKc23iPfBcRjFd_!!6000000007289-2-tps-1920-640.png',
            adverpicture: 'https://img.alicdn.com/imgextra/i4/O1CN019JZCWW1OZnyPkDvs0_!!6000000001720-2-tps-1920-771.png',
            code_img: 'https://kaola-haitao.oss.kaolacdn.com/ba5af72b-ee33-46a5-84f0-2652eaf31570_430_430.jpg',
            code_focus: 'https://kaola-haitao.oss.kaolacdn.com/5a81616e-383a-43a4-a53d-411377cacb0a_268_268.png',
            gotoRegister: `https://account.kaola.com/login.html?target=${encodeURIComponent(zkUrl)}`,
            zhuanke_help_agreementURL: 'https://cms-docs.kaola.com/publishModule/getPublishContentByKey.html?key=zhuanke_help_agreement',
            notice_icon: 'https://img.alicdn.com/tfs/TB1hAHThnM11u4jSZPxXXahcXXa-15-12.png',
            notice_right: 'https://img.alicdn.com/tfs/TB12Zfciz39YK4jSZPcXXXrUFXa-5-8.png',
            zhuankeHelpAgreement: '',
            regesiterDialogVisible: false,
            bindDialogVisible: false,
            agreeDialogVisible: false,
            isHasNotice: false,
            noticeAllList: [],
            isHomePage: true
        };
    },

    components: {
        homehead: homeHead,
        homefoot: homeFoot,
        ResignDialog
    },

    computed: {
        ...mapState([
            'isLogin',
            'kaolaEmailAddress',
            'kaolaHeadImageUrl',
            'kaolaNickName',
            'kaolaPhoneNum',
            'zhuankeType',
            'companyQualInfo',
            'zhuankeId'
        ]),
        noticeList(){
            if(this.noticeAllList.length >= 2){
                return [this.noticeAllList[0], this.noticeAllList[1]];
            }
            return [this.noticeAllList[0]];

        }
    },

    async created() {
        if(!this.companyQualInfo) {
            store.dispatch('getCompanyQualInfo');
        }
        await this.getNoticeInfo();
    },
    methods: {
        quitlogin(){
            logout();
        },
        /* eslint-disable */
        async loginin(){
            if(this.zhuankeType === 0) {
                this.$router.push({
                    path: '/promotion/goods'
                });
                return;
            } else {
                if(!this.companyQualInfo) {
                    await store.dispatch('getCompanyQualInfo');
                }
                if(!this.companyQualInfo){
                    this.isagreement = true;
                    this.getZhuankeHelpAgreement();
                    return;
                }
                if(this.companyQualInfo.status === 3){
                    this.$router.push({
                        path: '/promotion/goods'
                    });
                    return;
                }
                this.gotocompanyQualAduit();
            }
        },
        async registerPerson() {
            this.regesiterDialogVisible = true;
        },
        async registerV2() {
              const data = await this.$request({
                url: "/api/home/checkBindPhone",
                origin: true
            })

            if(data.code === 200 ) {
                this.regesiterDialogVisible = false;
                this.agreeDialogVisible = true;
            }
            if(data.code === 410){
                this.regesiterDialogVisible = false;
                this.bindDialogVisible = true;
            }
        },
        async agreeRules() {
            const data = await this.$request({
                url: '/api/home/register4PC',
                origin: true
            })
            if(data && data.code === 200) {
                this.agreeDialogVisible = false;
                 this.$message({
                type: 'success',
                message: '你已经成功开通个人赚客'
                })
                this.$router.push({
                    path: '/promotion/goods'
                });
                return;
            }else {
                this.agreeDialogVisible = false;
                this.$message.error(data.desc);
            }
        },
        async getZhuankeHelpAgreement(){
            if(this.zhuankeHelpAgreement) {
                return;
            }
            const data = await this.$request({
                url: this.zhuanke_help_agreementURL,
                origin: true
            });
            if(data.code === 200){
                this.zhuankeHelpAgreement = data.body.content;
            }
        },
        gotocompanyQualAduit(){
            this.$router.push({
                path: '/companyQualAudit'
            });
        },
        register(){
            location.href = this.gotoRegister;
        },
        bindPhone() {
            location.href = 'https://account.kaola.com/personal/account_management.html';
            this.bindDialogVisible = false;
        },
        async getNoticeInfo(){
             const data = await this.$request({
                url: '/api/notice/getNoticeInfo',
                origin: true,
                 params: {
                   pageIndex:1,
                   pageSize:10

                }
            });
            if(data.list && data.list.length > 0){
                this.isHasNotice = true;
                this.noticeAllList = data.list;
            }

        },
        gotoNoticePage(){
             this.$router.push({
                path: '/notice',
            });
        },
        gotoDetail(v){
            if(v){
                 this.$router.push({
                path: '/noticedetail',
                query:{
                    id:v.id
                }
            });}

        }

    }
};

</script>

<style lang="scss" scoped>
    .home{
        margin: -16px;
        height: 100%;
        &-main{
             width: 100%;
             min-height: 500px;
             position: relative;
             display: flex;
             align-items: center;
             .home-loginArea{
                 position: absolute;
                  right: 3.2%
             }
            .home-login{
                width: 400px;
                height: 354px;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 8px;
                border-radius: 8px;
                position: relative;

            }
            .is-show-notice{
                 position: absolute;
                top: 50%;
                transform:translateY(-50%);
                right: 3.2%
            }
            .register {
                height: 384px;
                // text-decoration: underline;
                // color: rgb(85, 148, 231);
            }
            .home-Notice{
                width: 400px;
                max-height: 80px;
                background: #FFFFFF;
                opacity: 1;
                border-radius: 8px;
                border-radius: 8px;
                margin-top: 5px;
                .notice-list{
                    display:flex;
                    justify-content:space-between;
                }
                .home-Notice-top{
                    padding: 13px 10px;
                    padding-bottom: 0;
                    display: flex;
                    justify-content: space-between;
                }
                .home-Notice-bottom{
                    padding: 3px 10px;
                    padding-bottom: 5px;;
                }
                .notice-title{
                    font-size: 13px;
                    font-weight: bold;
                    color: #000000;
                }
                .notice-font{
                    font-size: 12px;
                    color: #666666;
                    cursor:pointer;
                }
                .notice-content:hover{
                    color: #FF0000;
                }
                .notice-content{
                    width: 198px;
                    overflow: hidden;
                    white-space: nowrap;
                    line-height: 18px;
                    text-overflow: ellipsis;
                }
                .show-allnotice:hover{
                     color: #FF0000;
                }
            }
        }
        &-bgpicture img{
            width: 100%;
            min-height: 500px;
            align-self: auto;
        }
        .home-no-login{
            overflow: hidden;
        }
         .title{
            width: 100%;
            text-align: center;
            margin-top: 77px;
            font-weight: bold;
            font-size: 20px;
            color: #333333;
        }
        .userinfo-btn-login{
            display: block;
            width: 260px;
            height: 44px;
            border: none;
            margin: 0 70px;
            background-image: linear-gradient(270deg, #FF3163 0%, #FF0000 100%);
            border-radius: 22px;
            border-radius: 22px;
            position: absolute;
            top: 176px;
        }
        .jumplink {
            display: block;
            width: 260px;
            height: 44px;
            margin: 0 70px;
            position: absolute;
            top: 296px;
            text-align: center;
            color:#000;
            text-decoration:underline;
        }
        .btn-login{
            display: block;
            margin: 0 70px;
            margin-top: 48px;
            width: 260px;
            height: 44px;
            border: none;
            background-image: linear-gradient(270deg, #FF3163 0%, #FF0000 100%);
            border-radius: 22px;
            border-radius: 22px;
        }
        .userinfo-btn-login-person {
            display: block;
            width: 260px;
            height: 44px;
            border: none;
            margin: 0 70px;
            background-image: linear-gradient(270deg, #FF3163 0%, #FF0000 100%);
            border-radius: 22px;
            border-radius: 22px;
            position: absolute;
            top: 236px;
        }
        .btn-register{
            display: block;
            margin: 0 70px;
            margin-top: 20px;
            width: 260px;
            height: 44px;
            border: 1px solid #565656;
            border-radius: 22px;
            border-radius: 22px;
        }
        .btn-register:hover{
              border: none;
        }
        .tip{
             width: 100%;
            text-align: center;
            font-size: 14px;
            color: #333333;
            position: absolute;
            bottom: 15px;
        }
        &-userinfo{
            position: relative;
            p{
                width: 100%;
                text-align: center;
                font-size: 20px;
                color: #333333;
                font-weight: bold;
                position: absolute;
                top: 127px;
            }
        }
        &-userinfo-headimg{
            width: 100%;
            position: absolute;
            top: 69px;
            img{
                display: block;
                margin: 0 auto;
                width: 44px;
                height: 44px;
                border-radius: 50%;

            }
        }
        &-userinfo-personage{
            display: flex;
            justify-content:center;
            width: 100%;
            height: 44px;
            margin-top: 14px;
            img{
                 width: 44px;
                height: 44px;
                border-radius: 50%;
            }
            div{
                margin-left: 10px;
                font-size: 20px;
                color: #333333;
                 font-weight: bold;
                 display: inline;
                 line-height: 44px;
            }
        }
        &-adver img{
            width: 100%;
        }
        .code-img{
            width: 140px;
            height: 140px;
            margin: 0 auto;
            margin-top: 16px;
            position: relative;
            .code_focus{
            width: 140px;
            height: 140px;
            margin: 0 auto;
            }
            .QRcode_img{
                position: absolute;
                left: 5px;
                top: 5px;
                width: 130px;
                height: 130px;
                margin: 0 auto;
                z-index: 10;
            }
        }
        .weixin-tip{
            width: 100%;
            margin-top: 11px;
            text-align: center;
            font-size: 14px;
            color: #999999;
            text-align: center;
        }
        &-quit-login{
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 50px;
            text-align: center;
            line-height: 50px;
            background: #F5F5F5;
            font-size: 14px;
            color: #333333;
            border: 1px solid #E6E6E6;
            border-radius: 0 0 4px 4px;
            cursor: pointer;
        }
        .protocolName{
            color: red;
            text-decoration: underline;
            font-size: 16px;
        }
        .nickName{
            text-align: center;
            width: 101px;
            overflow:hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
        }
        .agreement-Dialog{
            max-height: 500px;
            overflow-y: auto;
        }
}

</style>
