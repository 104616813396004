import Vue from 'vue';
import Vuex from 'vuex';
import request from '../utils/request';
import sessionObj from '../utils/sessionObj';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isLogin: false,
        kaolaEmailAddress: '',
        kaolaNickName: '',
        kaolaPhoneNum: '',
        kaolaHeadImageUrl: '',
        zhuankeId: '',
        appSecret: '',
        zhuankeType: '',
        zhuankeStatus: '',
        selectedId: sessionObj.getStore('selectedId') ? sessionObj.getStore('selectedId'):[],
        selectedGoods: sessionObj.getStore('selectedGoods') ?sessionObj.getStore('selectedGoods'):[],
        helpMenuList: [],
        companyQualInfo: null,
        enterToSign: 0,
        enforceToSign: 0,
        enterNote: '',
        enforceNote: ''
    },

    mutations: {
        /* eslint-disable */
        updateUserInfo(state, info) {
            if(!info) {
                state.isLogin = false;
                return;
            }
            state.isLogin = true;
            state.kaolaEmailAddress = info.kaolaEmailAddress;
            state.kaolaNickName = info.kaolaNickName;
            state.kaolaPhoneNum = info.kaolaPhoneNum;
            state.kaolaHeadImageUrl = info.kaolaHeadImageUrl;
            state.zhuankeId = info.zhuankeId;
            state.appSecret = info.appSecret;
            state.zhuankeType = info.zhuankeType;
            state.zhuankeStatus = info.zhuankeStatus;
            state.zhuankeLevel = info.zhuankeLevel;
        },
        updatecompanyInfo(state, info){
            state.companyQualInfo = info;
        },
        getStoreId(state) {
            state.selectedId = sessionObj.getStore('selectedId');
        },
        getStoreGoods(state) {
            state.selectedGoods = sessionObj.getStore('selectedGoods');
        },
        deleteSelectedId(state, playload) {
            if(!playload.allFlag){
                state.selectedId.forEach((value, index, arr) => {
                    if(playload.id === value) {
                        arr.splice(index, 1);
                    }
                });
            }
            if(playload.allFlag){
                state.selectedId=[];
            }
        },
        deleteSelectedGoods(state, playload) {
            if(!playload.allFlag){
                state.selectedGoods.forEach((value, index, arr) => {
                    if(playload.id === value.goodsId) {
                        arr.splice(index, 1);
                    }
                });
            }
            if(playload.allFlag){
                state.selectedGoods=[];
            }
        },
        pushSelectedId(state, value) {
            state.selectedId.push(value);
        },
        pushSelectedGoods(state, value) {
            state.selectedGoods.push(value);
        },

        updateHelpMenuList(state, list) {
            state.helpMenuList = list || [];
        },
        updateSignDisconf(state, disconfInfo){
            state.enterToSign = disconfInfo.enterToSign;
            state.enforceToSign = disconfInfo.enforceToSign;
            state.enterNote = `${disconfInfo.enterNote}详情请到考拉赚客公众号查看。`;
            state.enforceNote = `${disconfInfo.enforceNote}详情请到考拉赚客公众号查看。`;
        },
    },

    actions: {
        async getUserInfo({ commit }) {
            try {
                const result = await request({
                    origin: true,
                    toast: false,
                    url: '/api/user/info'
                });
                if(result.code === 200) {
                    commit('updateUserInfo', result.data);
                } else {
                    commit('updateUserInfo', null);
                }
            } catch (e) {
                commit('updateUserInfo', null);
            }
        },
        async getCompanyQualInfo({ commit }){
            try {
                const respones = await request({
                    origin: true,
                    toast: false,
                    url: '/api/user/companyQualcation'
                });
                if(respones.code === 200) {
                    commit('updatecompanyInfo', respones.data);
                } else {
                    commit('updatecompanyInfo', {});
                }
            } catch (e) {
                commit('updatecompanyInfo', {});
            }
        },

        async getHelpMenuList({ commit }) {
            try {
                const data = await request({
                    toast: false,
                    url: '/api/help/menu'
                });
                commit('updateHelpMenuList', data);
            } catch (e) {
                commit('updateHelpMenuList', []);
            }
        },

        async getSignDisconf({commit}) {
            const data = await request({url: '/api/getSignDisconf'});
            commit('updateSignDisconf', data);
        },
    }
});
