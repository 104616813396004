
import formatTime from '../utils/format-time';
import formatPrice from '../utils/format-price';
import thumbnail from '../utils/thumbnail';
import formatBizType from '../utils/format-biz-type';

const filters = {
    formatTime,
    formatPrice,
    thumbnail,
    formatBizType
};

export default {
    install(Vue) {
        Object.keys(filters).map((key) => {
            Vue.filter(key, filters[key]);
        });
    }
};
