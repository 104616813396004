
<template>
    <el-header class="zk-header" style="height: 70px;">
        <div class="zk-logo">
            <img class="zk-logo-img" :src="logo" alt=""
                 @click="jump2Home"
            >

            <p class="help-text" v-if="isHelpPage">帮助中心</p>

            <p class="zk-collapse" @click="handleCollapse"
               v-if="!isHelpPage"
            >
                <i class="el-icon-s-operation" />
            </p>
        </div>
        <div class="zk-header-userinfo">
            <div class="zk-header-help" v-if="!isHelpPage">
                <router-link to="/help">帮助中心</router-link>
            </div>

            <el-dropdown @command="handleCommand" v-if="kaolaNickName">
                <span class="el-dropdown-link" style="font-weight: bold;">
                    {{ kaolaNickName }}&nbsp;&nbsp;<i class="el-icon-caret-bottom" />
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="info" icon="el-icon-s-tools"
                                      v-if="isAudit"
                    >
                        账号管理
                    </el-dropdown-item>
                    <el-dropdown-item command="quit" icon="el-icon-user-solid">
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </el-header>
</template>

<script>
import { mapState } from 'vuex';
import logout from '../../utils/logout';
import Bus from '../../utils/bus.js';
import store from '../../store';

export default {
    props: {
        isHelpPage: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            logo: '//kaola-haitao.oss.kaolacdn.com/c0cd6999-71c0-40ce-be1f-e227d95b9ada_420_80.png',
            isAudit: false
        };
    },
    computed: {
        ...mapState([
            'kaolaNickName',
            'companyQualInfo',
            'zhuankeType'
        ])
    },
    async created() {
        if(!this.companyQualInfo) {
            await store.dispatch('getCompanyQualInfo');
        }
        if(this.zhuankeType === 0) {
            this.isAudit = true;
        }
        if(this.zhuankeType === 1 && this.companyQualInfo && this.companyQualInfo.status === 3){
            this.isAudit = true;
        }
    },
    methods: {
        handleCommand(command) {
            if(command === 'quit') {
                this.quit();
                return;
            }
            if(command === 'info') {
                this.$router.push('/accountManagement');
            }
        },

        quit() {
            this.$confirm('', '确定退出该账号吗？')
                .then(() => {
                    logout();
                })
                .catch(() => {});
        },

        handleCollapse() {
            this.$emit('collapse-change');
            Bus.$emit('changeCollapse');
        },

        jump2Home() {
            window.location.href = '/';
        }
    }
};
</script>

<style lang="scss">
.zk-header {
    border-bottom: 1px solid #ccc;
    position: relative;
    height: 80px;
    background: #fff;

    .zk-logo {
        padding-left: 60px;
        position: absolute;
        left: 0;
        top: 50%;
        height: 38px;
        margin-top: -19px;
        text-align: center;
        cursor: pointer;
        &-img {
            width: 198px;
            height: 38px;
        }

        .help-text {
            margin-left: 20px;
            display: inline-block;
            vertical-align: super;
            font-size: 16px;
        }

        .zk-collapse {
            position: absolute;
            right: -30px;
            height: 32px;
            line-height: 32px;
            top: 50%;
            margin-top: -16px;
            font-size: 18px;
            cursor: pointer;
        }
    }

    &-userinfo {
        cursor: pointer;
        position: absolute;
        right: 40px;
        height: 32px;
        line-height: 32px;
        top: 50%;
        margin-top: -16px;
    }
}

.zk-header-help {
    display: inline-block;
    margin-right: 40px;
    font-weight: bold;
}

.el-dropdown-menu__item {
    white-space: nowrap !important;
}

</style>
