import Vue from 'vue';
import './styles/reset.scss';
import './styles/element-variables.scss';
import request from './utils/request';
import components from './plugins/components';
import filters from './plugins/filters';
import App from './App.vue';
import router from './router';
import store from './store';

Vue.config.productionTip = false;
Vue.prototype.$request = request;

Vue.use(filters);
Vue.use(components);

async function initApp() {
    await store.dispatch('getUserInfo');

    new Vue({
        router,
        store,
        render: (h) => h(App)
    }).$mount('#app');
}

initApp();
