export default [{
    path: '/accountManagement',
    name: 'accountManagement',
    component: () => import(/* webpackChunkName: "accountManagement" */ '../../views/accountManagement/accountManagement.vue'),
    meta: { title: '账号管理' }
}, {
    path: '/companyQualAudit',
    name: 'companyQualAudit',
    component: () => import(/* webpackChunkName: "companyQualAduit" */ '../../views/companyQualAudit/companyQual.vue'),
    meta: { hideHeaderAndASide: true }
}, {
    path: '/level',
    name: 'level',
    component: () => import(/* webpackChunkName: "companyQualAduit" */ '../../views/accountManagement/zhuanke-level-info.vue'),
    meta: { title: '等级权益' }
}];
