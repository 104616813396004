<template>
    <el-dialog
        title="重要通知"
        :visible.sync="dialogVisible"
        width="75%"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :show-close="false"
    >
        <p class="resign-text">亲爱的赚客：</p>
        <p class="resign-text">
            由于平台结算政策调整，您需先前往赚客公众号完成
            <span class="red">实名认证</span>
            并
            <span class="red">授权合作公司为您结算</span>，
            以免影响您之后的推广和结算，详情请到考拉赚客公众号查看。完成后刷新页面重新进入即可正常操作。
        </p>
        <p class="resign-text centerme">
            <img src="https://kaola-haitao.oss.kaolacdn.com/ba5af72b-ee33-46a5-84f0-2652eaf31570_430_430.jpg" alt="">
            <br>
            <span class="tip">微信扫描登录考拉赚客公众号</span>
        </p>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogVisible: false
        };
    },

    async mounted() {
        try {
            let data = await this.$request({
                url: 'https://zk.kaola.com/api/shouldShowSignNewTip',
                toast: false,
                loading: false
            });
            data = data || {};
            this.dialogVisible = data.settleSignStatus === 2;
        } catch(e) {
            // eslint-disable-next-line
            console.log(e);
        }
    }
};
</script>

<style lang="scss" scoped>
.resign-text {
    margin-bottom: 10px;
    font-size: 14px;
    &.centerme {
        text-align: center;
    }
    img {
        width: 200px;
        height: 200px;
    }
    span.red {
        color: #ff0000;
    }
    span.tip {
        color: #666;
        font-size: 12px;
    }
}
</style>
