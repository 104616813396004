<template>
    <div class="home-foot">
        <div class="privarcyAndLink">
            <div class="privarcyAndLink_main">
                <a :href="privarcyUrl">隐私政策</a>
                <span class="division">|</span>
                <a :href="tokaola">考拉海购</a>
                <span class="division">|</span>
                <span>
                    联系邮箱：yonglian.fyl@alibaba-inc.com
                </span>
            </div>
        </div>
        <div class="copyrightAndsecurity">
            <span>增值电信业务经营许可证：浙B2-20160288 </span>

            <img :src="securityRecord" alt="公安备案" class="securityRecordImg">
            <a :href="securityRecordUrl">
                浙公网安备 33010802002216号
            </a>
            <span>阿里巴巴版权所有    2003-2023</span>
            <span>浙ICP备16011229号-6</span>
        </div>


        <div class="bottonIcon">
            <img class="trustWeb" :src="trustWebicon" alt="可信网站">
        </div>
    </div>
</template>
<script>
export default {
    name: 'homeFoot',
    data(){
        return{
            tokaola: 'https://www.kaola.com/',
            privarcyUrl: 'https://you.kaola.com/common/page.html?key=Privacy_policy',
            trustWebicon: '//kaola-haitao.oss.kaolacdn.com/d720d83b55a04b6f932ea845c673c5bf.png',
            securityRecord: '//kaola-haitao.oss.kaolacdn.com/f28aa8ee818a42bf832341f605eccefb.png',
            securityRecordUrl: 'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010802002216&no_protofix'
        };
    }
};
</script>
<style scoped lang="scss">
.home-foot{
            width: 100%;
            height: 241px;
            position: relative;
            background: #222224;
            a{
                text-align: center;
                display: inline-block;
                font-size: 14px;
                color: #c2c2c2;
            }
            span{
                font-size: 14px;
                color: #c2c2c2;
            }
        }
        .privarcyAndLink{
            width: 100%;
            position: absolute;
            top: 62px;
            text-align: center;
            display: flex;
            justify-content: center;
            &_main{
                margin: 0 auto;
            }
        }
        .copyrightAndsecurity{
            width: 100%;
            position: absolute;
            top: 107px;
             font-size: 14px;
            color: #c2c2c2;
            display: flex;
            justify-content: center;
            span{
                display: inline-block;
                margin: 0 20px;
            }
        }
        .securityRecordImg{
            margin: 0 4px;
        }
        .division{
            display: inline-block;
            width: 50px;
            text-align: center;
        }
        .bottonIcon {
            width: 100%;
            position: absolute;
            top: 153px;
            img{
                display: block;
                width: 86px;
                height: 32px;
                margin: 0 auto;
                }
        }
</style>
