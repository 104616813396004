
export default [{
    path: '/promotion/goods',
    name: 'goodsPromotion',
    component: () => import(/* webpackChunkName: "goodsPromotion" */ '../../views/promotion/goodsPromotion.vue'),
    meta: { title: '商品推广' }
},
{
    path: '/promotion/activity',
    name: 'activityPromotion',
    component: () => import(/* webpackChunkName: "activityPromotion" */ '../../views/promotion/activityPromotion.vue'),
    meta: { title: '主题推广' }
},
{
    path: '/promotion/reward',
    name: 'rewardPromotion',
    component: () => import(/* webpackChunkName: "rewardPromotion" */ '../../views/promotion/rewardPromotion.vue'),
    meta: { title: '奖励活动推广' }
},
{
    path: '/promotion/reward/rewardDetail',
    name: 'rewardDetail',
    component: () => import(/* webpackChunkName: "rewardDetail" */ '../../views/promotion/rewardDetail.vue'),
    meta: { title: '奖励活动推广', activePath: '/promotion/reward' }
},
{
    path: '/promotion/search',
    name: 'searchPromotion',
    component: () => import(/* webpackChunkName: "searchPromotion" */ '../../views/promotion/search.vue'),
    meta: { title: '搜索', activePath: '/promotion/goods' }
},
{
    path: '/promotion/promoteManage',
    name: 'promoteManage',
    component: () => import(/* webpackChunkName: "rewardDetail" */ '../../views/promotion/promoteManage.vue'),
    meta: { title: '推广位管理', activePath: '/promotion/promoteManage' }
}
];
