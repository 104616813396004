export default [{
    path: '/notice',
    name: 'notice',
    component: () => import(/* webpackChunkName: "help" */ '../../views/notice/noticeListPage.vue'),
    meta: { hideHeaderAndASide: true }
}, {
    path: '/noticedetail',
    name: 'noticedetail',
    component: () => import(/* webpackChunkName: "help" */ '../../views/notice/noticedetail.vue'),
    meta: { hideHeaderAndASide: true }
}

];
