
<template>
    <el-menu class="zk-menu"
             :default-active="activePath"
             :router="true"
             :collapse="isCollapse"
    >
        <template v-for="(topmenu, index) in menuList">
            <template v-if="topmenu.children && topmenu.children.length">
                <el-submenu :index="`${index}`">
                    <template slot="title">
                        <i :class="topmenu.icon" />
                        <span slot="title">{{ topmenu.title }}</span>
                    </template>
                    <el-menu-item v-for="item in topmenu.children" :key="item.path"
                                  :index="item.path"
                    >
                        {{ item.title }}
                    </el-menu-item>
                </el-submenu>
            </template>
            <template v-else>
                <el-menu-item :index="topmenu.path">
                    <i :class="topmenu.icon" />
                    <span slot="title">{{ topmenu.title }}</span>
                </el-menu-item>
            </template>
        </template>
    </el-menu>
</template>

<script>

export default {
    props: ['isCollapse', 'menuList', 'isHelpPage'],
    data() {
        return {
            activePath: ''
        };
    },

    watch: {
        $route: {
            handler(to) {
                /* eslint-disable */
                if(!this.isHelpPage) {
                    this.activePath = to.meta.activePath || to.path;
                } else {
                    this.activePath = decodeURIComponent(to.fullPath);
                }
                /* eslint-enable */
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss">
.zk-menu {
    overflow: auto;
    .el-submenu .el-menu-item {
        min-width: 180px;
    }
}
.zk-menu:not(.el-menu--collapse) {
    width: 200px;
    height: 100%;
}
.zk-menu {
    flex-shrink: 0;
    height: 100%;
}
</style>
