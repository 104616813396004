
import ElementUI from 'element-ui';
import VueLazyload from 'vue-lazyload';

const components = {
    ElementUI,
    VueLazyload
};

export default {
    install(Vue) {
        Object.keys(components).map((key) => {
            Vue.use(components[key]);
        });
    }
};
