export default function formatBizType(bizType) {
    if (bizType === 0) {
        return '考拉海购';
    }
    if (bizType === 1) {
        return '猫享自营';
    }
    if (bizType === 2) {
        return '天猫商家';
    }
    return '';
}
