
export default [{
        path: '/tools/transformLink',
        name: 'transformLink',
        component: () => import(/* webpackChunkName: "transformLink" */ '../../views/tools/transformLink.vue'),
        meta: { title: '超级转链' }
    },
    {
        path: '/tools/advanceOrderSearch',
        name: 'advanceOrderSearch',
        component: () => import(/* webpackChunkName: "advanceOrderSearch" */ '../../views/tools/advanceOrderSearch.vue'),
        meta: { title: '预付订单查询' }
    },
    {
        path: '/tools/redPacketPromotion',
        name: 'redPacketPromotion',
        component: () => import(/* webpackChunkName: "redPacketPromotion" */ '../../views/tools/redPacketPromotion.vue'),
        meta: { title: '红包推广查询' }
    },
    {
        path: '/tools/seo/list',
        name: 'list',
        component: () => import(/* webpackChunkName: "seoList" */ '../../views/seo/list.vue'),
        meta: { title: '赚密令列表' }
    }
];
