
/* eslint-disable */
export default function formatPrice(value) {
    let result = [];
    let counter = 0;
    value = (value || 0).toString();
    const arr = value.split('.');
    const intValue = arr[0] || '';
    const decValue = arr[1] || '';
    for(let i = intValue.length - 1; i >= 0; i--) {
        counter++;
        result.unshift(intValue[i]);
        if(counter % 3 === 0 && i !== 0) {
            result.unshift(',');
        }
    }
    return decValue ? `${result.join('')}.${decValue}` : result.join('');
}
/* eslint-enable */
