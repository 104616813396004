
<template>
    <el-container class="zk-container zk-container-root">
        <zk-header v-if="!hideHeaderAndASide" :is-help-page="isHelpPage"
                   @collapse-change="handleCollapse"
        />

        <el-container class="zk-container">
            <zk-aside v-if="!hideHeaderAndASide" :is-collapse="isCollapse"
                      :menu-list="menuList" :is-help-page="isHelpPage"
            />
            <el-main class="zk-main">
                <slot />
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import ZkHeader from './header';
import ZkASide from './aside';

export default {
    props: {
        hideHeaderAndASide: {
            type: Boolean
        },
        menuList: {
            type: Array
        },
        isHelpPage: {
            type: Boolean
        }
    },

    data() {
        return {
            isCollapse: false
        };
    },
    components: {
        'zk-header': ZkHeader,
        'zk-aside': ZkASide
    },

    methods: {
        handleCollapse() {
            this.isCollapse = !this.isCollapse;
        }
    }
};
</script>

<style lang="scss">
.zk-container-root {
    height: 100%;
    flex-direction: column;

    .zk-container {
        flex: 1;
        height: 100%;
    }
}
.zk-main {
    padding: 16px;
    background: #f5f5f5;
    height: 100%;
    overflow: auto;

    &-title {
        margin-bottom: 16px;
        h2 { font-size: 18px; }
    }
}
</style>
