
export default [{
    path: '/report/reward',
    name: 'reportReward',
    component: () => import(/* webpackChunkName: "reportReward" */ '../../views/report/reward'),
    meta: { title: '奖励活动' }
}, {
    path: '/report/all',
    name: 'reportAll',
    component: () => import(/* webpackChunkName: "reportAll" */ '../../views/report/all'),
    meta: { title: '整体报表' }
}, {
    path: '/report/order',
    name: 'reportOrder',
    component: () => import(/* webpackChunkName: "reportAll" */ '../../views/report/order'),
    meta: { title: '订单明细' }
}];
