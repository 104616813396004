<template>
    <div class="home-banner">
        <div class="index-logo">
            <img class="index-logo-img" :src="logo" @click="jump2Home">
        </div>
        <div class="index-right">
            <div class="home-help">
            <router-link to="/help">帮助中心</router-link>
        </div>
        <div class="home-login" v-if="!isLogin && !isHomePage">
            <router-link to="/">登录注册</router-link>
        </div>
        <div class="home-userinfo">
            <el-dropdown @command="handleCommand" v-if="isLogin && kaolaNickName && !isHomePage">
                <span class="el-dropdown-link" style="font-weight: bold;">
                    {{ kaolaNickName }}&nbsp;&nbsp;<i class="el-icon-caret-bottom" />
                </span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="info" icon="el-icon-s-tools"
                                      v-if="isAudit"
                    >
                        账号管理
                    </el-dropdown-item>
                    <el-dropdown-item command="quit" icon="el-icon-user-solid">
                        退出登录
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
        </div>
        
    </div>
</template>
<script>
import { mapState } from 'vuex';
import store from '../../store';
import logout from '../../utils/logout';


export default {
    name: 'homeHead',
    props:{
        isHomePage:{
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            logo: 'https://kaola-haitao.oss.kaolacdn.com/c0cd6999-71c0-40ce-be1f-e227d95b9ada_420_80.png',
            isAudit: false
        };
    },
       computed:{
         ...mapState([
            'isLogin',
            'kaolaNickName',
            'companyQualInfo',
            'zhuankeType'
        ]),
    },
    async created(){
        if(!this.companyQualInfo) {
            await store.dispatch('getCompanyQualInfo');
        }
        if(this.zhuankeType === 0) {
            this.isAudit = true;
        }
        if(this.zhuankeType === 1 && this.companyQualInfo && this.companyQualInfo.status === 3){
            this.isAudit = true;
        }
    },
    methods: {
        jump2Home() {
            window.location.href = '/';
        },
         handleCommand(command) {
            if(command === 'quit') {
                this.quit();
                return;
            }
            if(command === 'info') {
                this.$router.push('/accountManagement');
            }
        },
         quit() {
            this.$confirm('', '确定退出该账号吗？')
                .then(() => {
                    logout();
                })
                .catch(() => {});
        },

    }
};
</script>
<style scoped lang="scss">

     .home-banner{
            width:100%;
            height: 70px;
            position: relative;
            background: #FFFFFF;
            border-bottom: 1px solid #cecdcd;
            display: flex;
            align-items: center;
            justify-content: space-between;
         }
        .index-right{
            display: flex;
            justify-content: flex-end;
            margin-right: 40px;
            align-items: baseline;
        }
        .index-logo {
            width: 210px;
            padding-left: 60px;
            line-height: 70px;
             display: flex;
            align-items: center;
            &-img {
                 width: 210px;
                 height: 40px;
            }
        }
        .home-help{
            // position: absolute;
            // top: 0;
            // right: 230px;
            line-height: 70px;
            display: inline-block;
            margin-right: 40px;
            font-weight: bold;
        }
        .home-login{
            font-weight: bold;
            // position: absolute;
            // top: 0;
            // right: 40px;
            line-height: 70px;
             display: inline-block;
            // top: 50%;
            // margin-top: -16px;
        }
        .home-userinfo{
            //  position: absolute;
            // right: 40px;
            height: 32px;
            line-height: 32px;
            // top: 50%;
            // margin-top: -16px;
        }

</style>
