
export default {
    get(key, type) {
        type = `${type || 'session'}Storage`;
        return window[type].getItem(key);
    },

    set(key, value, type) {
        type = `${type || 'session'}Storage`;
        window[type].setItem(key, value);
    },

    remove(key, type) {
        type = `${type || 'session'}Storage`;
        window[type].removeItem(key);
    },

    clear(type) {
        type = `${type || 'session'}Storage`;
        window[type].clear();
    }
};
