import Vue from 'vue';
import Router from 'vue-router';
import Home from '../views/home.vue';
import helpRoutes from './help/index';
import setTitle from '../utils/set-title';
import promotionRoutes from './promotion';
import account from './accountManagement';
import settleCenterRoutes from './settle-center';
import reportRoutes from './report';
import developmentRoutes from './development';
import toolsRoutes from './tools';
import noticeRoutes from './notice';

// fix NavigationDuplicated
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

Vue.use(Router);

const routes = [
    ...[{
        path: '/',
        name: 'home',
        component: Home,
        meta: { hideHeaderAndASide: true }
    }],
    ...helpRoutes,
    ...promotionRoutes,
    ...settleCenterRoutes,
    ...reportRoutes,
    ...account,
    ...developmentRoutes,
    ...toolsRoutes,
    ...noticeRoutes
];

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if(to.meta && to.meta.title) {
        setTitle(to.meta.title);
    }
    next();
});

export default router;
