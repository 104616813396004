
import axios from 'axios';
import { Message } from 'element-ui';
import Cookies from 'js-cookie';

axios.defaults.timeout = 100*1000;

axios.defaults.withCredentials = true;

const RESPONSE_ERROR = '请求出错，请刷新重试！';
const SERVER_ERROR = '服务器错误，请稍后再试！';

export default async function request(config) {
    config = {
        origin: false,
        toast: true,
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'x-csrf-token': Cookies.get('csrfToken')
        },
        ...config
    };

    if(config.method === 'post') {
        config.data = config.data || config.params;
    }

    return new Promise((resolve, reject) => {
        /* eslint-disable */
        axios(config).then((response) => {
            const data = response.data;
            if(config.origin) {
                return resolve(data);
            }

            const { code, desc } = data;
            if(!code) {
                config.toast && Message.error(SERVER_ERROR);
                return reject({ code: 500, desc: SERVER_ERROR });
            }

            if (code === 200) {
              return resolve(data.data);
            }

            // 首页请求都使用 origin:true 处理
            if(code === 403) {
               window.location.replace('/');
               return;
            }

            if(code === 490) {
                window.location.replace('/');
                return;
            }

            let res = {code, desc: desc || RESPONSE_ERROR}
            let info = ''
            if(data.data && typeof data.data === 'string') {
                info = res.data =  data.data
            }

            config.toast && Message.error(info || desc || RESPONSE_ERROR);
            return reject(res);
        }).catch(() => {
            config.toast && Message.error(SERVER_ERROR);
            reject({ code: 500, desc: SERVER_ERROR });
        });
        /* eslint-enable */
    });
}
