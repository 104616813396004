<template>
    <div id="app" class="zk-app">
        <layout :hide-header-and-a-side="hideHeaderAndASide"
                :menu-list="menuList"
                :is-help-page="isHelpPage"
        >
            <keep-alive>
                <router-view v-if="$route.meta.keepAlive" />
            </keep-alive>
            <router-view v-if="!$route.meta.keepAlive" />
        </layout>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import Layout from './components/layout';
import appMenuList from './utils/menu-list';
import store from './store';

export default {
    data() {
        return {
            hideHeaderAndASide: false,
            menuList: appMenuList,
            isHelpPage: false
        };
    },

    components: {
        layout: Layout
    },

    mounted() {
        if(this.isLogin) {
            // checkStatus
        }
    },

    computed: {
        ...mapState([
            'helpMenuList',
            'zhuankeType',
            'isLogin',
            'kaolaEmailAddress',
            'kaolaHeadImageUrl',
            'kaolaNickName',
            'kaolaPhoneNum',
            'zhuankeType',
            'companyQualInfo',
            'zhuankeId'
        ])
    },

    methods: {
        async doWhiteList() {
            // const m = await xxx()
        }
    },

    watch: {
        $route: {
            /* eslint-disable */
            async handler(to) {
                this.hideHeaderAndASide = !!to.meta.hideHeaderAndASide;
                if(to.path === '/help') {
                    this.isHelpPage = true;
                    if(!this.helpMenuList.length) {
                        await store.dispatch('getHelpMenuList');
                    }
                    this.menuList = this.helpMenuList;
                    const key = to.query.key;
                    if(!key) {
                        const menu = this.menuList[0];
                        if(menu.path) {
                            this.$router.replace(menu.path);
                            return;
                        }
                        if(menu.children && menu.children.length) {
                            this.$router.replace(menu.children[0].path);
                        }
                    }
                } else {
                    this.isHelpPage = false;
                    this.menuList = appMenuList(this.zhuankeType);
                }
            },
            immediate: true
            /* eslint-enable */
        }
    }
};
</script>

<style lang="scss">
.zk-app {
    height: 100%;
    min-width: 1366px;
    .el-table .cell {
        white-space: nowrap;
    }
}
</style>
